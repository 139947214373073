import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LayoutTerms from "../components/Terms/layoutTerms"
import SEO from "../components/seo"

const TermsPage = () => (
  <LayoutTerms>
    <SEO title="LifeSync Corporation Terms and Conditions" />
    <Container className="cookieSheet">
        <Row>
            <Col>

              <p>LifeSync Corporation (the "Company") assent to the sale of its products is expressly conditioned upon Customer acceptance of the terms and conditions stated herein. Customer acceptance of delivery of the goods will constitute acceptance of these terms and conditions and confirms that these terms and conditions hereof govern the transaction. These terms and conditions shall supersede any provisions, terms, and conditions contained on any purchasing order, confirmation order, or other writing or verbal statements from the customer that are inconsistent with the terms herein or provide additional or different terms or conditions to those contained in this Order Acknowledgment. The Company expressly rejects all other terms and conditions proposed by Customer, and the rights of the parties shall be governed exclusively by the provisions, terms and conditions hereof.</p>
              
              <p>The Company makes no representation or warranties covering any Customer order except such as are expressly contained herein. The Company may at any time make changes to these terms and conditions provided a written notification is provided to the Customer. These terms and conditions govern any purchase of the Company's goods or services and may not be changed or modified except by mutual written agreement.</p>
              
              <ol className="list-numbered">
                <li><u>Applicability</u>.
                  <ol className="list-lettered">
                    <li>These terms and conditions of sale (these "Terms") are the only terms that govern the sale of the Goods ("Goods") or Services ("Services") by the seller named on the reverse side of these Terms ("Seller") to the buyer named on the reverse side of these Terms ("Buyer"). Notwithstanding anything herein to the contrary, if a written contract signed by both parties is in existence covering the sale of the Goods or Services covered hereby, the terms and conditions of said contract shall prevail to the extent they are inconsistent with these Terms.</li>
                    <li>The accompanying sales order (the "Sales Order") and these Terms (collectively, this "Agreement") comprise the entire agreement between the parties, and supersede all prior understandings, agreements, negotiations, and communications. These Terms prevail over any of Buyer's general terms and conditions of purchase regardless of whether or when Buyer has submitted its purchase order or such terms. Fulfillment of Buyer's order does not constitute acceptance of any of Buyer's terms and conditions and does not serve to modify or amend these Terms.</li>
                  </ol>
                </li>
                <li><u>Delivery</u>.
                <ol className="list-lettered">
                    <li>The Goods included in an accepted purchase order will be delivered within a reasonable time after the receipt of Buyer's purchase order and all necessary information, subject to product availability and specific delivery instructions as outlined in Buyer's purchase order. Seller shall not be liable for any delays, loss, or damage in transit.</li>
                    <li>Unless otherwise agreed in writing by the parties, Seller shall deliver the Goods to Seller's Location as designated by Seller (the "Delivery Point") using Seller's standard methods for packaging and shipping such Goods. Buyer shall take delivery of the Goods promptly of Seller's written notice that the Goods have been delivered to the Delivery Point. Buyer shall be responsible for all loading costs and provide equipment and labor reasonably suited for receipt of the Goods at the Delivery Point.</li>
                    <li>Seller may, upon agreement with Buyer, without liability or penalty, make partial shipments of Goods to Buyer. Each shipment will constitute a separate sale, and Buyer shall pay for the units shipped whether such shipment is in whole or partial fulfillment of Buyer's purchase order.</li>
                    <li>If for any reason Buyer fails to accept delivery of any of the Goods on the date fixed pursuant to Seller's notice that the Goods have been delivered at the Delivery Point, or if Seller is unable to deliver the Goods at the Delivery Point on such date because Buyer has not provided appropriate instructions, documents, licenses or authorizations: (i) risk of loss to the Goods shall pass to Buyer; (ii) the Goods shall be deemed to have been delivered; and (iii) Seller, at its option, may store the Goods until Buyer picks them up, whereupon Buyer shall be liable for all related costs and expenses (including, without limitation, storage, and insurance).</li>
                  </ol>
                </li>
                <li><u>Orders and Quantity</u>.
                <ol className="list-lettered">
                    <li>All accepted purchase orders received after 2:30pm EST will be processed the next business day, subject to availability. The Seller will notify the Buyer of any backorders. Backorder will be filled upon availability of inventory. Prior to the shipment of any Goods covered by an accepted purchase order, the Seller may change the construction or the design of the Goods without notice to the Buyer as long as the function and the performance of the Product are not substantially altered.</li>
                    <li>If Seller delivers to Buyer a quantity of Goods of up to 95% more or less than the quantity set forth in the Sales Confirmation, Buyer shall agree with Seller to amend respective order and not object to or reject the Goods or any portion of them by reason of the surplus or shortfall, and shall pay for such Goods the price set forth in the Sales Confirmation adjusted pro rata.</li>
                  </ol>
                </li>
                <li><u>Shipping Terms</u>. All Goods are shipped Ex Works (EXW) as defined in INCOTERMS 2023. Subject to the foregoing, Seller shall make delivery in accordance with the terms on the face of the Sales Order Confirmation or Acknowledgement.</li>
                <li><u>Title and Risk of Loss</u>. Title and risk of loss pass to Buyer upon shipment of Goods from the Seller's Location. As collateral security for the payment of the purchase price of the Goods, Buyer hereby grants to Seller a lien on and security interest in and to all of the right, title, and interest of Buyer in, to, and under the Goods, whether now existing or hereafter arising or acquired, and in all accessions thereto and replacements or modifications thereof, and all proceeds thereof. The security interest granted under this provision constitutes a purchase money security interest under the Florida Uniform Commercial Code.</li>
                <li><u>Amendment and Modification</u>. These Terms may only be amended or modified in writing which specifically states that it amends these Terms and is signed by an authorized representative of each party.</li>
                <li><u>Inspection and Rejection of Nonconforming Goods</u>. 
                <ol className="list-lettered">
                    <li>Buyer shall inspect the Goods promptly, and in any event within five (5) business days of receipt ("Inspection Period"). Buyer will be deemed to have accepted the Goods unless it notifies Seller in writing during the Inspection Period that the delivered Goods are different than identified in Seller's specifications ("Nonconforming Goods").</li>
                    <li>Buyer shall provide to Seller such written documentation of Nonconforming Goods as required by Seller. </li>
                    <li>If Seller agrees the subject Goods are Nonconforming Goods, Seller shall, in its sole discretion, (i) replace such Nonconforming Goods with Conforming Goods, or (ii) credit or refund the Price for such Nonconforming Goods, together with any reasonable shipping and handling expenses incurred by Buyer in connection therewith. Buyer shall ship, at its expense and risk of loss, the Nonconforming Goods to Seller's facility located at Seller's designated location and include a completed return material authorization (RMA) form.  If Seller exercises its option to replace Nonconforming Goods, Seller shall, after receiving Buyer's shipment of Nonconforming Goods, ship to Buyer, at Buyer's expense and risk of loss, the replaced Goods to the Delivery Point. </li>
                    <li>Buyer acknowledges and agrees that the remedies set forth in Section 7(c) are Buyer's exclusive remedies for the delivery of Nonconforming Goods. Except as provided under Section 7(c), all sales of Goods to Buyer are made on a one-way basis and Buyer has no right to return Goods purchased under this Agreement to Seller.</li>
                  </ol>
                </li>
                <li><u>Returns</u>. Buyer shall request an RMA within fifteen (15) days of receiving Goods and return them in their original condition within thirty (30) days, including original packaging and completed RMA form and in accordance with the warranty policy described in Section 11 if applicable. All returns are subject to a 15% restocking fee.  Buyer shall ship, at its expense and risk of loss, returned Goods to Seller's facility located at Seller's designated location.</li>
                <li><u>Price</u>.
                <ol className="list-lettered">
                    <li>Buyer shall purchase the Goods from Seller at the price (the "Price") set forth in Seller's published price lists in force as of the date that Seller accepts Buyer's purchase order. All Prices are exclusive of all sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any Governmental Authority on any amounts payable by Buyer. Buyer shall be responsible for all such charges, costs, and taxes; provided, that, Buyer shall not be responsible for any taxes imposed on, or with respect to, Seller's income, personnel, or assets. </li>
                    <li>Each party agrees to comply with all applicable federal and state laws in connection with this Agreement, including, without limitation, the federal Anti-Kickback Statute, 42 U.S.C. § 1320a-7b(b). The parties acknowledge and agree that pricing terms as provided under this Agreement may constitute a "discount or other reduction in price" for purposes of the federal Anti-Kickback Statute, 42 U.S.C. § 1320a-7b(b).  Customer agrees to fully and accurately report any discount or other remuneration received and, upon request by the Secretary of the U.S. Department of Health and Human Services or by a state agency, to make available information provided by the Company as contemplated under the applicable provisions of the Anti-Kickback Statute and related regulations. </li>
                    <li>All prices are subject to change in the event of any changes in cost of materials or labor, specifications, quantities, delivery schedules, customs duties, other factors beyond Seller's control, or in the event of delays caused by instructions of the Buyer, or failure of the Buyer to give Seller adequate information. In addition, prices payable by the Buyer shall be subject to immediate increase, should the Seller as a result of governmental action or regulation including, without limitation, those contemplated by an investigation under Section 232 of the Trade Expansion Act of 1962 (19 U.S.C. §1862) or those contemplated by an investigation under Section 301 of the Trade Act of 1974 (19 U.S.C. §2411), incur additional duties, tariffs or restrictions on products sold hereunder, or on the raw materials that are used in making such products.  All price changes shall be communicated to Buyer with a thirty (30) days' notice.</li>
                    <li>Without limiting the foregoing, all prices are subject to adjustment at any time prior to shipment in the event that the prices or costs related to any component, materials, parts, or commodities utilized in the Goods or Services have increased following the acceptance of any Order for any Goods or Services (a "Component Adjustment").  Any Component Adjustment, as determined by Seller, shall be reflected in the invoice for Goods or Services that is transmitted from Seller to Buyer in accordance with the terms and conditions hereof. All Component Adjustments shall be communicated to Buyer with a thirty (30) days' notice.</li>
                  </ol>
                </li>
                <li><u>Payment Terms</u>.
                <ol className="list-lettered">
                    <li>Buyer shall pay all invoiced amounts for Goods and Services and applicable government taxes and surcharges due to Seller within thirty (30) days of Seller's invoice, unless otherwise provided in writing by a mutual agreement of both parties. Buyer shall make all payments hereunder by electronic funds transfers in the preferred form of a wire or ACH payment and in US dollars. </li>
                    <li>Buyer shall pay interest on all late payments at the lesser of the rate of 1.5% per month or the highest rate permissible under applicable law. Buyer shall reimburse Seller for all costs incurred in collecting any late payments, including, without limitation, attorneys' fees. In addition to all other remedies available under these Terms or at law (which Seller does not waive by the exercise of any rights hereunder), Seller shall be entitled to suspend the delivery of any Goods if Buyer fails to pay any amounts when due hereunder and such failure continues for thirty (30) days following written notice thereof.</li>
                    <li>Buyer shall not withhold payment of any amounts due and payable by reason of any set-off of any claim or dispute with Seller, whether relating to Seller's breach, bankruptcy, or otherwise.</li>
                  </ol>
                </li>
                <li><u>Limited Warranty Disclosures</u>.
                <ol className="list-lettered">
                    <li>Seller shall provide Buyer with Seller's limited warranty from the date of shipment of all Goods ("Warranty Period"), that such Goods will materially conform to Seller's specifications in effect as of the date of manufacture and will be free from functional defects in material and workmanship. Seller provides a limited warranty for specific Goods as follows: (i) for Reusable ECG Cables, Finger Clip Sp02 Sensors, Molded Cable Leadwires, NIBP Cuffs, NIBP Adapter Hoses, Sp02 Adapters, and Extension Cables Warranty Period shall be one (1) year from shipment date; (ii) for Reusable ECG Shielded Leadwires, ECG Adapters, Yokemates, and Surgical Cables Warranty Period shall be six (6) months from shipment date; (iii) for Disposable Leadwires, LeadWear, Surgical Cables, and Neuro Products Warranty Period for single-patient use shall be three (3) months from shipment date; and (iv) for Reusable ECG Unshielded Leadwires Warranty Period shall be one (1) month from shipment date.</li>
                    <li>EXCEPT FOR THE WARRANTY SET FORTH IN SECTION 11(A), SELLER MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO THE GOODS, AND  WITHOUT LIMITING THE FOREGOING, SELLER MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO (i) MERCHANTABILITY; (ii) FITNESS FOR A PARTICULAR PURPOSE; (iii) TITLE; OR (IV) INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; IN EACH CASE WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.</li>
                    <li>Products manufactured by a third party ("Third Party Product") may constitute, contain, be contained in, incorporated into, attached to, or packaged together with, the Goods. Third-Party Products are expressly excluded from any warranty in Section 11(a). For the avoidance of doubt, SELLER MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY THIRD-PARTY PRODUCT, INCLUDING ANY (i) WARRANTY OF MERCHANTABILITY; (ii) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; (iii) WARRANTY OF TITLE; OR (iv) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.</li>
                    <li>The following are conditions to any liability of the Seller for a breach of the warranty set forth in Section 11(a): (i) Buyer must give written notice of the defect, reasonably described, to Seller within the specified timeframe, as set forth in 11(a) from the time when Buyer received and inspected the Goods (but beginning no later than expiration of the Inspection Period); (ii) Seller must be given a reasonable opportunity after receiving the notice to examine such Goods and Buyer (upon Seller's request) returns such Goods to Seller's place of business at Seller's cost for the examination to take place there; and (iii) Seller has reasonably verified Buyer's claim that the Goods are defective.</li>
                    <li>Seller shall not be liable for a breach of the warranty set forth in Section 11(a) if: (i) Buyer makes any further use of such Goods after giving such notice; (ii) the defect arises because Buyer failed to follow Seller's oral or written instructions as to the storage, installation, commissioning, use or maintenance of the Goods; or (iii) Buyer alters or repairs such Goods without Seller's prior written consent; (iv) Goods are damaged, neglected, misused, or improperly operated; (v) Goods are modified, disassembled, or reassembled; (vi) Goods are used with other accessories other than those manufactured and distributed by Seller; (vii) Goods are put to a use or used in environments for which they are not intended. Seller's warranty does not extend to any equipment or software not manufactured by Seller.</li>
                    <li>Subject to Section 11(d) and Section 11(e) above, with respect to any such Goods during the Warranty Period, Seller will, in its sole discretion, either: (i) replace such Goods (or the defective part) or (ii) credit or refund the price of such Goods at the pro rata contract rate provided that, if Seller so requests, Buyer shall, at Seller's expense, return such Goods to Seller. THE REMEDIES SET FORTH IN THIS SECTION 11(F) SHALL BE THE BUYER'S SOLE AND EXCLUSIVE REMEDY AND SELLER'S ENTIRE LIABILITY FOR ANY BREACH OF THE LIMITED WARRANTY SET FORTH IN SECTION 11(A).</li>
                  </ol>
                </li>
                <li><u>Limitation of Liability</u>. IN NO EVENT SHALL SELLER BE LIABLE TO BUYER OR ANY THIRD PARTY FOR ANY LOSS OF USE, REVENUE OR PROFIT OR LOSS OF DATA OR DIMINUTION IN VALUE, OR FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT SELLER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE. IN NO EVENT SHALL SELLER'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL OF THE AMOUNTS PAID TO SELLER FOR THE GOODS SOLD HEREUNDER.</li>
                <li><u>Compliance with Law</u>. Buyer shall comply with all applicable laws, regulations, and ordinances, including without limitation U.S. Anti-Kickback Statute, 42 U.S.C. § 1320a. Buyer shall maintain in effect all the licenses, permissions, authorizations, consents, and permits that it needs with respect to the Goods and to carry out its obligations under this Agreement. Buyer shall comply with all export and import laws of all countries involved in the sale of the Goods under this Agreement or any resale of the Goods by Buyer. Buyer assumes all responsibility for shipments of Goods requiring any government import clearance. Seller may terminate this Agreement if any governmental authority imposes antidumping or countervailing duties or any other penalties on Goods.</li>
                <li><u>Termination</u>. In addition to any remedies that may be provided herein, Seller may terminate this Agreement with immediate effect upon written notice to Buyer, if Buyer: (i) fails to pay any amount when due under this Agreement and such failure continues for thirty (30) days after Buyer's receipt of written notice of nonpayment; (ii) has not otherwise performed or complied with any of these Terms, in whole or in part; or (iii) becomes insolvent, files a petition for bankruptcy or commences or has commenced against it proceedings relating to bankruptcy, receivership, reorganization or assignment for the benefit of creditors.</li>
                <li><u>Waiver</u>. No waiver by Seller of any of the provisions of this Agreement is effective unless explicitly set forth in writing and signed by Seller. No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from this Agreement operates or may be construed, as a waiver thereof. No single or partial exercise of any right, remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</li>
                <li><u>Confidential Information</u>. All non-public, confidential or proprietary information of Seller, including but not limited to specifications, samples, patterns, designs, plans, drawings, documents, data, business operations, customer lists, pricing, discounts, or rebates, disclosed by Seller to Buyer, whether disclosed orally or disclosed or accessed in written, electronic or other form or media, and whether or not marked, designated or otherwise identified as "confidential" in connection with this Agreement is confidential, solely for the use of performing this Agreement, and may not be disclosed or copied unless authorized in advance by Seller in writing. Upon Seller's request, Buyer shall promptly return all documents and other materials received from Seller. Seller shall be entitled to injunctive relief for any violation of this Section. This Section does not apply to information that is: (a) in the public domain; or (b) rightfully disclosed to and obtained by Buyer on a non-confidential basis from a third party.</li>
                <li><u>Force Majeure</u>.  No party shall be liable or responsible to the other party, nor be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (except for any obligations of Buyer to make payments to Seller hereunder), when and to the extent such failure or delay is caused by or results from acts beyond the impacted party's ("Impacted Party") control, including, without limitation, the following force majeure events ("Force Majeure Event(s)"): (a) acts of God; (b) flood, fire, earthquake, pandemics, or explosion; (c) war, invasion, hostilities (whether war is declared or not), terrorist threats or acts, riot or other civil unrest; (d) government order, law, or actions; (e) embargoes or blockades in effect on or after the date of this Agreement; (f) strikes, labor stoppages or slowdowns, or other industrial disturbances; and (g) shortage of adequate power or transportation facilities; and (h) other similar events beyond the reasonable control of the Impacted Party. The Impacted Party shall give notice within thirty (30) days of the Force Majeure Event to the other party, stating the period of time the occurrence is expected to continue. The Impacted Party shall use diligent efforts to end the failure or delay to minimize the effects of such Force Majeure Event. The Impacted Party shall resume the performance of its obligations as soon as reasonably practicable after the removal of the cause. In the event that the Impacted Party's failure or delay remains uncured for a period of thirty (30) consecutive days following written notice given by it under this Section 17, either party may thereafter terminate this Agreement upon thirty (30) days' written notice.</li>
                <li><u>Assignment</u>. Buyer shall not assign any of its rights or delegate any of its obligations under this Agreement without the prior written consent of Seller. Any purported assignment or delegation in violation of this Section is null and void. No assignment or delegation relieves Buyer of any of its obligations under this Agreement.  Seller may assign this Agreement without the need for consent from Buyer.</li>
                <li><u>Relationship of the Parties</u>. The relationship between the parties is that of independent contractors. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the parties, and neither party shall have authority to contract for or bind the other party in any manner whatsoever.</li>
                <li><u>No Third-Party Beneficiaries</u>. This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer upon any other person or entity any legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of these Terms.</li>
                <li><u>Governing Law</u>. All matters arising out of or relating to this Agreement are governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule (whether of the State of Florida or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than those of the State of Florida.</li>
                <li><u>Submission to Jurisdiction</u>. Any legal suit, action, or proceeding arising out of or relating to this Agreement shall be instituted in the federal courts of the United States of America or the courts of the State of Florida in each case located in the County of Broward, State of Florida, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding.</li>
                <li><u>Notices</u>. All notices, requests, consents, claims, demands, waivers, and other communications hereunder (each, a <b>"Notice"</b>) shall be in writing and addressed to the parties at the addresses set forth on the face of the Sales Confirmation or to such other address that may be designated by the receiving party in writing. All Notices shall be delivered by personal delivery, nationally recognized overnight courier (with all fees pre-paid), facsimile (with confirmation of transmission), or certified registered mail (in each case, return receipt requested, postage prepaid). Except as otherwise provided in this Agreement, a Notice is effective only (a) upon receipt of the receiving party, and (b) if the party giving the Notice has complied with the requirements of this Section.</li>
                <li><u>Severability</u>. If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.</li>
              </ol>
            </Col>
        </Row>
    </Container>
  </LayoutTerms>
)

export default TermsPage
